<template>
  <v-card class="pa-2" flat>
    <p class="font-weight-medium">Educational Background</p>
    <v-row v-for="(educ, index) in education" :key="index" align="center" no-gutters>

      <!-- Pre-Law Area -->
      <div v-if="educ.level === 'Pre-Law'">
        <v-col cols="12" class="py-1 pr-1">
          <span class="font-weight-medium">Pre-Law</span>
        </v-col>

        <v-row align="center" no-gutters>
          <v-col cols="12">
            <v-row align="center" no-gutters>
              <v-col cols="1" class="text-right">
                <v-icon small>mdi-school</v-icon>
              </v-col>
              
              <v-col cols="11" class="pl-3">
                <span class="primary--text">{{ educ.degree }}</span>
              </v-col>

              <v-col cols="1" class="text-right">
                <v-icon small>mdi-home-city</v-icon>
              </v-col>
              
              <v-col cols="11" class="pl-3">
                <span class="primary--text">{{ educ.school }}</span>
              </v-col>
            </v-row>
          </v-col>
          
        </v-row>
      </div>
      
      <!-- Pre-Law Area -->

      <!-- Law Area -->
      <div v-if="educ.level === 'Law'">
        <v-col cols="12" class="py-1 pr-1">
          <span class="font-weight-medium">Law</span>
        </v-col>

        <v-row align="center" no-gutters>
          <v-col cols="1" class="text-right">
            <v-icon small>mdi-school</v-icon>
          </v-col>
          
          <v-col cols="11" class="pl-3">
            <span class="primary--text">{{ educ.degree }}</span>
          </v-col>

          <v-col cols="1" class="text-right">
            <v-icon small>mdi-home-city</v-icon>
          </v-col>
          
          <v-col cols="11" class="pl-3">
            <span class="primary--text">{{ educ.school }}</span>
          </v-col>
        </v-row>
      </div>
      
      <!-- Law Area -->

      <!-- Masters Area -->
      <div v-if="educ.level === 'Masters'">
        <v-col cols="12" class="py-1 pr-1 pt-3">
          <span class="font-weight-medium">Masters</span>
        </v-col>

        <v-col cols="12">
          <v-row align="center" no-gutters>
            <v-col cols="1" class="text-right">
              <v-icon small>mdi-school</v-icon>
            </v-col>
            
            <v-col cols="11" class="pl-3">
              <span class="primary--text">{{ educ.degree }}</span>
            </v-col>

            <v-col cols="1" class="text-right">
              <v-icon small>mdi-home-city</v-icon>
            </v-col>
            
            <v-col cols="11" class="pl-3">
              <span class="primary--text">{{ educ.school }}</span>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <!-- Masters Area -->
      
    </v-row>

     <v-col cols="12" class="pt-2 text-right" @click="dialog = true">
        <v-btn color="primary" depressed dark dense small>Add Educational Background</v-btn>
      </v-col>

      <v-dialog v-model="dialog" width="300" persistent>
        <v-card class="pa-2">
          <v-form ref="educForm">
            <v-row align="center" no-gutters>

            <v-col cols="8" class="pb-2">
              <span class="font-weight-medium">Details</span>
            </v-col>

            <v-col cols="4" class="pb-2 text-right">
              <v-btn @click="dialog = false, $refs.educForm.reset()" icon text small>
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" class="pb-2">
              <v-text-field 
                label="Degree" 
                v-model="degree" 
                dense 
                outlined 
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" class="pb-2">
              <v-text-field 
                label="School" 
                v-model="school" 
                dense 
                outlined 
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" class="pb-2">
              <v-select 
                :items="levels" 
                label="Level" 
                v-model="level" 
                dense 
                outlined 
                hide-details
              >
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-btn :loading="isLoading" color="primary" @click="addEducation()" dark depressed small block>Save</v-btn>
            </v-col>

          </v-row>
          </v-form>
          
        </v-card>
      </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'EducationalBackground',
  data () {
    return {
      isLoading: false,
      dialog: false,
      levels: [ 'Pre-Law', 'Law', 'Masters' ],
      degree: '',
      school: '',
      level: ''
    }
  },
  methods: {
    addEducation () {
      let education = {
        degree: this.degree, 
        school: this.school, 
        level: this.level
      }
      this.isLoading = true
      this.$store.dispatch('user/setEducationalDegree', education)
        .then(res => {
          this.dialog = false
          this.isLoading = false
        })
    }
  },
  computed: {
    education () {
      return this.$store.getters['user/user'].user.education
    }
  }
}
</script>